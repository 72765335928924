import config from '../config';

export const authenticateGoogle = async (activate: boolean): Promise<void> => {
  const redirectUri = `${config.AUTHENTICATION_API}/social/`;
  const base = 'https://accounts.google.com/o/oauth2/v2/auth';
  const state = {
    provider: 'google',
    is_activation: activate,
  };
  const params = {
    client_id: config.GOOGLEID || '',
    redirect_uri: redirectUri,
    response_type: 'code',
    access_type: 'offline',
    scope: 'email profile',
    state: JSON.stringify(state),
  };
  const queryString = Object.entries(params)
    .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
    .join('&');
  window.location.href = `${base}?${queryString}`;
};

export const authenticateFacebook = async (activate: boolean): Promise<void> => {
  const clientId = config.FACEBOOKID;
  const redirectUri = `${config.AUTHENTICATION_API}/social/`;
  const scope = 'email';
  const state = {
    provider: 'facebook',
    is_activation: activate,
  };
  const url = `https://www.facebook.com/v2.12/dialog/oauth?client_id=${clientId}&redirect_uri=${redirectUri}&scope=${scope}&state=${JSON.stringify(
    state,
  )}`;
  window.location.href = url;
};

export const authenticateApple = async (activate: boolean): Promise<void> => {
  const clientId = config.APPLEID;
  const redirectUri = `${config.AUTHENTICATION_API}/social/`;
  const base = 'https://appleid.apple.com/auth/authorize';
  const state = {
    provider: 'apple',
    is_activation: activate,
  };
  const params = {
    response_type: 'code id_token',
    response_mode: 'form_post',
    scope: 'email',
    client_id: clientId,
    redirect_uri: redirectUri,
    state: JSON.stringify(state),
  };
  const queryString = Object.entries(params)
    .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
    .join('&');
  window.location.href = `${base}?${queryString}`;
};
